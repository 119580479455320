<template>
	<div>
	<ContentHeader title="Absensi Karyawan"  />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Absensi Karyawan</h3>
	            			<div class="col-6 text-right">
	            			</div>
	            		</div>
	            		<div class="card-body">
            				<form @submit.prevent="prosesAbsensi" class="mb-4">
            					<div class="row">
            						<div class="col-6">
            							<div class="form-group">
						                  <label>Nama karyawan</label>
						                  <select class="custom-select form-control" v-model="absensi.karyawan_id">
						                  	<option disabled="">- Pilih Karyawan -</option>
						                  	<option v-for="row in karyawan" :key="row.id" :value="row.id">{{ row.nama_lengkap }}</option>
						                  </select>
						                </div>
            						</div>
            					</div>
				                <button type="submit" class="btn btn-primary d-flex align-items-center">
								  <div>
								  	Submit
								  </div>	
								  <div>
				                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
				                      <g>
				                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
				                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
				                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
				                      </g>
				                    </svg>
				                  </div>
								</button>
            				</form>
            				<div class="row align-items-center mt-4">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="getDataResult">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            				</div>
	            				<div class="col-lg-8"></div>
	            			</div>
	            			<div class="spinner-border loading" v-if="loading_screen" role="status">
							  <span class="sr-only">Loading...</span>
							</div>
							<div class="layer"></div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO</th>
							      <th scope="col" class="text-sm">NAMA KARYAWAN</th>
							      <th scope="col" class="text-sm">TANGGAL</th>
							      <th scope="col" class="text-sm">WAKTU MASUK</th>
							      <th scope="col" class="text-sm">ISTIRAHAT MASUK</th>
							      <th scope="col" class="text-sm">ISTIRAHAT KELUAR</th>
							      <th scope="col" class="text-sm">WAKTU KELUAR</th>
							      <th scope="col" class="text-sm">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in data_result" :key="row.id">
							    	<td>{{ index + 1 }}</td>
							    	<td>{{ row.karyawan.nama_lengkap }}</td>
							    	<td>{{ row.tanggal }}</td>
							    	<td>{{ row.waktu_masuk.slice(11, 16) }}</td>
							    	<td>{{ format_waktu_keluar(row.istirahat_masuk) }}</td>
							    	<td>{{ format_waktu_keluar(row.istirahat_keluar) }}</td>
							    	<td>{{ format_waktu_keluar(row.waktu_keluar) }}</td>
							    	<td>
							    		<div class="d-flex justify-content-around">
								    		<button @click="istirahatMasuk(row.id)" class="btn btn-sm btn-info text-white" title="Mulai Waktu Istirahat">Break </button>
								    		<button @click="istirahatKeluar(row.id)" class="btn btn-sm btn-info text-white" title="Akhiri Waktu Istirahat">End Break</button>
							    		</div>
							    		<div class="d-flex justify-content-around mt-1">
								    		<button @click="prosesPulang(row.id)" class="btn btn-sm btn-warning text-white" title="Waktu Pulang">Check Out</button>
								    		<button @click="konfirmasiHapus(row.id)" class="btn btn-sm btn-danger">Hapus</button>
							    		</div>
							    	</td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>

		<!-- Modal -->
		<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg" role="document">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        ...
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
		        <button type="button" class="btn btn-primary">Save changes</button>
		      </div>
		    </div>
		  </div>
		</div>

    </div>
</template>

<style>
	.loading {
	  position: fixed;
	  z-index: 999;
	  height: 2em;
	  width: 2em;
	  overflow: show;
	  margin: auto;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	}

</style>

<script>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed, reactive } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import format_nominal from '@/format_nominal.js'

export default{
	components: {
		ContentHeader: ContentHeader,
	},
    setup(){
    	const user = computed(() => store.getters['auth/user'])
		const data_result = ref([])
		const memuat_data = ref(true)
		const cek_data = ref(false)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])
		const karyawan = ref([])
		const absensi = reactive({
			'cabang_id' : user.value.cabang_id,
			'karyawan_id' : '',
		})
		const loading = ref(false)
		const loading_screen = ref(false)
		const tahun = ref('')
		const bulan = ref('')

		const getKaryawan = async () => {
			let { data } = await axios.get(`api/karyawan/getAll/${user.value.cabang_id}`)

			if (data != 'kosong') {
				karyawan.value = data
				console.log(karyawan.value)
			}
		}

		const getDataResult = async () => {
			data_result.value = []
	    	memuat_data.value = true
	    	cek_data.value = false

			let { data } = await axios.get(`api/absensi/get/${user.value.cabang_id}/${page.value}/${tahun.value}-${bulan.value}`)

			if (data != 'kosong') {
				memuat_data.value = false
				cek_data.value = false
				data_result.value = data.data
				links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
			} else {
				cek_data.value = true
				memuat_data.value = false
				data_result.value = []
			}

			console.log(data_result.value)
		}

		const paginateData = async (url) => {

    		if (url != null) {
    			data_result.value = []
	    		memuat_data.value = true

	    		let link_url = new URL(url)
	    		link_url = `${link_url.pathname}${link_url.search}`
	    		
	    		let { data } = await axios.get(`${link_url}`)
	    		
	    		if (data != 'kosong') {
	    			memuat_data.value = false
	    			data_result.value = data.data	
	    			links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    		}
    		}
    	}

    	const prosesAbsensi = () => {
    		loading.value = true
			axios.post('api/absensi/create', absensi)
			.then((response) => {
				Swal.fire({
				  text: 'Berhasil Menanbahkan Data',
				  icon: 'success',
				  showConfirmButton: false,
				  timer: 1500
				})	
				loading.value = false
				getDataResult()
				console.log(response)
			})
			.catch((error) => {
				Swal.fire({
				  text: 'Gagal Menanbahkan Data',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})	
				loading.value = false
				console.log(error)
			})
    	}

    	const konfirmasiHapus = (id) => {
	        Swal.fire({
	          title: 'Hapus Data?',
	          icon: 'warning',
	          showCancelButton: true,
	          confirmButtonText: `Ya`,
	          cancelButtonText: `Batal`,
	        }).then((result) => {
	          /* Read more about isConfirmed, isDenied below */
	          if (result.isConfirmed) {
	            hapusData(id)
	          } 
	        })
	    }

	    const hapusData = (id) => {
    		axios.delete(`api/absensi/destroy/${id}`)
    		.then((response) => {
    			Swal.fire({
		            text: 'Berhasil Menghapus Data',
		            icon: 'success',
		            showConfirmButton: false,
		            timer: 1500
		          })
    			console.log(response.data)
	    		getDataResult()
    		})
    		.catch((error) => {
    			Swal.fire({
					  text: 'Gagal menghapus data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})
    			console.log(error)
    		})
    	}

    	const prosesPulang = (id) => {
    		loading_screen.value = true
    		axios.put(`api/absensi/update_waktu_pulang/${id}`)
    		.then((response) => {
    			if (response.data == 'sudah ada') {
    				Swal.fire({
			            text: 'Karyawan Sudah Check Out ',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
	    			loading_screen.value = false
    			} else {
    				Swal.fire({
			            text: 'Berhasil ',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
	    			console.log(response.data)
	    			loading_screen.value = false
		    		getDataResult()
    			}
    		})
    		.catch((error) => {
    			Swal.fire({
				  text: 'Gagal ',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})
				loading_screen.value = false
    			console.log(error)
    		})	
    	}

    	const istirahatMasuk = (id) => {
    		loading_screen.value = true
    		axios.put(`api/absensi/update_istirahat_masuk/${id}`)
    		.then((response) => {
    			if (response.data == 'sudah ada') {
    				Swal.fire({
			            text: 'Karyawan Sudah Istirahat ',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
	    			loading_screen.value = false
    			} else {
    				Swal.fire({
			            text: 'Berhasil ',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
	    			console.log(response.data)
	    			loading_screen.value = false
		    		getDataResult()
    			}
    		})
    		.catch((error) => {
    			Swal.fire({
				  text: 'Gagal ',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})
				loading_screen.value = false
    			console.log(error)
    		})	
    	}

    	const istirahatKeluar = (id) => {
    		loading_screen.value = true
    		axios.put(`api/absensi/update_istirahat_keluar/${id}`)
    		.then((response) => {
    			if (response.data == 'sudah ada') {
    				Swal.fire({
			            text: 'Karyawan Sudah Kerja Kembali ',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
	    			loading_screen.value = false
    			} else {
    				Swal.fire({
			            text: 'Berhasil ',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
	    			console.log(response.data)
	    			loading_screen.value = false
		    		getDataResult()
    			}
    		})
    		.catch((error) => {
    			Swal.fire({
				  text: 'Gagal ',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})
				loading_screen.value = false
    			console.log(error)
    		})	
    	}

    	const format_waktu_keluar = (waktu) => {
    		if (waktu == null) {
    			return 'Masih Kosong'
    		} else {
    			return waktu.slice(11, 16)
    		}
    	}


		onMounted(() => {
			getDataResult()
			getKaryawan()
		})

		return { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, karyawan, prosesAbsensi, absensi, loading, konfirmasiHapus, format_nominal, format_waktu_keluar, prosesPulang, loading_screen, istirahatMasuk, istirahatKeluar }
    }
}
</script>

<style>
	
</style>